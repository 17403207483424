import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby'
import Layout, { ActiveLinkStyle } from '../components/layout'
import SEO from '../components/seo'
import StartLeaveRequestLink from '../components/StartLeaveRequestLink'
import PrivateRoute from '../components/PrivateRoute'

export default function Overview() {
  const data = useStaticQuery(graphql`
    query OverviewPage {
      site {
        siteMetadata {
          pathPrefix
        }
      }
    }
  `)
  const { pathPrefix } = data.site.siteMetadata

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="Overview" />
        <div className="content-outline-998">
          <div id="twocol-content">
            <h1 className="header-underline color1">
              Welcome to the Leave Center
            </h1>
            <p>
              This portal was designed to provide information and  documents necessary to successfully manage your leave of absence.
              If you  require time off for three (3) or more consecutive days, please click on the <strong>Submit a Leave Request</strong>&nbsp;
              button at the  bottom of the page to start the leave of absence process with the Palace Entertainment Leave  Center.
            </p>
            <p>
              <br />
              The below links provide useful information on Federal and  State leave provisions and benefits.
              The View Laws by State tab will advise if  the state you work in has additional state leave benefits and will include a  link to any applicable state resources.
            </p>
            <br />
            <h2 className="color3"> Federal Leave</h2>
            <ul>
              <li>
                <h5>
                  <a
                    href={`${pathPrefix}/Documents/FMLA Family and Medical Leave Act (FMLA) Employee Guide.pdf`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Family and Medical Leave Act Employee Guide
                  </a>
                </h5>
              </li>
              <li>
                <h5>
                  <a
                    href={`${pathPrefix}/Documents/FMLA Frequently Asked Questions.pdf`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    FMLA Frequently Asked Questions
                  </a>&nbsp;
                </h5>
              </li>
              <li>
                <h5>
                  <a
                    href={`${pathPrefix}/Documents/FMLA Military Leave Frequently Asked Questions.pdf`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Military Leave Provisions{' '}
                  </a>
                </h5>
              </li>
            </ul>
            <p>&nbsp;</p>
            <h2 className="color3">State Leave</h2>
            <ul>
              <li>
                <h5>
                  <Link to="/LawsByState/" activeStyle={ActiveLinkStyle}>
                    View Our Leave Laws By State Resource Page &raquo;
                  </Link>
                </h5>
              </li>
            </ul>
            <p>&nbsp;</p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a id="button">
              <StartLeaveRequestLink>
                Submit A Leave Request »
              </StartLeaveRequestLink>
            </a>
            <p>&nbsp;</p>
          </div>
          <div id="twocol-pic">
            <img src={`${pathPrefix}/styles/images/pic-leaveoverview_3.jpg`} alt="leave overview" width="100%" />
            <p>&nbsp;</p>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}
